import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import Swal from 'sweetalert2'

//
import Layout from '../components/layout'
import HeroInternal from '../components/HeroInternal'

export default (props) => {
  const [formData, setFormData] = React.useState({
    subject: 'Website Contact Form',
  })
  const _onSubmit = e => {
    e.preventDefault()

    fetch('/api/contactForm', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message
      })
    }).then((res) => {
      if (res.status >= 400) {
        console.error('there was an error', res)
        return Swal.fire({
          icon: 'error',
          title: 'Oops... Something went wrong!',
          text: res.statusTest,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        })
      } else {
        setFormData({
          name: '',
          email: '',
          message: ''
        })

        return Swal.fire({
          icon: 'success',
          title: 'Thank you for your message.',
          text: 'We will be in touch soon.',
          customClass: {
            confirmButton: 'primary'
          },
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        })
      }
    })
  }
  const _handleInputChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const page = props.data.sanityPage

  return (
    <Layout slug={page.slug}>
      <HeroInternal
        {...R.find(R.propEq('_type', 'heroImage'))(page._rawContent)}
      />
      <section className={`wrapper style1 page page--${page.slug}`}>
        <div className="inner">
          <div className="spotlights">
            <section>
              <div className="content">
                <header className="major">
                  <h3>Send Us a Message</h3>
                </header>
              </div>
            </section>
            <form
              name="contact-form"
              data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              data-netlify="true"
              className="alt form form--contact-us"
              onSubmit={_onSubmit}
            >
              <input type="hidden" name="form-name" value="contact-form" />
              <div className="row gtr-uniform">
                <div className="col-6 col-12-xsmall">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={_handleInputChange}
                  />
                </div>
                <div className="col-6 col-12-xsmall">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className="email"
                    required
                    value={formData.email}
                    onChange={_handleInputChange}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    id="message"
                    placeholder="Enter your message"
                    rows="6"
                    name="message"
                    required
                    className="message"
                    value={formData.message}
                    onChange={_handleInputChange}
                  />
                </div>
                <div className="col-12">
                  <ul className="actions">
                    <li>
                      <input type="submit" value="Send Message" />
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const ContactQuery = graphql`
  query {
    sanityPage(slug: {current: {eq: "contact-us"}}) {
      _rawContent(resolveReferences: {maxDepth: 4})
      slug {
        current
      }
      pageTitle
    }
  }
`
